.Top-part{
    background: #fff;
    position: fixed;
    z-index: 5;
    height: 70px;
    width: 100%;
    padding: 0px 150px;
    box-shadow: 0px 6px 9px #e5e5e5;
    line-height: 70px;

    .left-part{
        float: left;
        display: flex;
        flex-direction: row;

        img{
            margin: 23px 20px 0 0;
            height: 28px;
            width: 32px;
        }

        p{
            font-size: 23px;
            color: #52575e;
        }
    }

    .right-part{
        float: right;
        display: flex;
        flex-direction: row;

        span{
            height: 28px;
            width: 32px;
            font-size: 23px;
            color: #52575e;
            margin-right: 8px;

            &:hover{
                color: #1A73E8;
            }
            &:hover .settings{
                display: block;
            }
            .settings{
                color:#464747 ;
                background: #fff;
                height: fit-content;
                width: 230px;
                list-style: none;
                font-size: 17px;
                box-shadow: 0px 0px 5px 1px #c2c2c2;
                border-radius: 5px;
                transform: translate(-225px, -20px);
                padding: 7px 0 ;
                // transform: translateY(-20px);
                li{
                    padding:20px;
                    line-height: 0px;

                    &:hover{
                        background: #d1d1d1;
                    }
                }
            }
        }

        .email{
            margin-top: -3px;
            // padding-right: 10px;
            outline: none;
            border: none;
            font-size: 15px;
            color: #52575e;
            
            span{
                font-size: 80%;
                font-weight: bold;
            }
            &:hover{
                color: #1A73E8;
            }
            &:hover .optn{
                display: block;
            }
            .optn{
                color:#464747 ;
                background: #fff;
                height: fit-content;
                width: 215px;
                list-style: none;
                font-size: 15px;
                box-shadow: 0px 0px 5px 1px #c2c2c2;
                border-radius: 5px;
                transform: translate(-20px, -20px);
                padding: 7px 0 ;
                // transform: translateY(-20px);
                li{
                    padding:10px 5px 10px 10px;
                    line-height: 8px;

                    &:hover{
                        background: #d1d1d1;
                    }
                }
            }
        }
        .hidden{
            display: none;
        }
    }
}