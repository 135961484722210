    .rightpane{
        // width: 960px;
        // background: #f1f3f4;
        // padding-top: 85px;
        .carosul{
            // margin: 0 20px 50px 20px;
            // width:920;
            // height: 380px;
            // border-bottom: 2px solid #5f6368;;
            .slider-wrapper{
                padding: 0px 20px;
                button.slick-arrow.slick-prev::before{
                    color: #000;
                }
                button.slick-arrow.slick-next::before {
                    color: #000;
                }
                .slick-list {
                    width: 90%;
                    margin: auto;
                }
            }
        }
    }