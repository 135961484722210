.recom-body{
    width: 920px;
    height: fit-content;
    margin: 0 20px 50px 20px;

    .heading{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
        margin-top: 30px;
        padding-top: 30px;
        border-top: 2px solid #e8eaed;
        cursor: pointer;
        
        p{
            width: fit-content;
            font-size: 25px;
            color: #202124;
        }
        span{
            color: #1a73e8;
            background: #fff;
            border:1px solid #5f6368;
            border-radius: 5px;
            height: 40px;
            width: 100px;
            font-size: 17px;
            font-weight: bold;
            line-height: 35px;
            text-align: center;
            border: 1px solid #dadce0;
            line-height: 40px;  
        }
    } 
    .cards-part{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .card1:nth-child(4n-7){
            margin-left: 0 !important;
        }
        .card1{
            width: 23%;
            margin: 0px 0px 16px 24px;
        .upper-part{
            img{
                height: 190px !important;
                max-width: 100%;
                object-fit: cover;
                width: 100%;
            }
        }       
        .lower-part{
            color:#5f6368 ;
            p,span{
                margin: 5px 0;
            }
        }
    }
    }
}