*{margin: 0;
    padding: 0;
    box-sizing: border-box;}
    
    .appstore-section{
        flex-flow: wrap;
        max-width: 1200px;
        margin: auto;
    }
    
    @media (min-width:1200px) and (max-width:1220px){
        .appstore-section .rightpane .recom-body {
            width: 900px;
        }
    }
    
    @media (min-width:992px) and (max-width:1199px){
        .appstore-section .rightpane .recom-body {
            width: 692px;
        }
        .appstore-section .rightpane .recom-body .cards-part .card1:nth-child(4n-4) {
            margin-left: 0 !important;
        }
        
    }
    
    @media (min-width:768px) and (max-width:991px){
        .appstore-section .rightpane .recom-body {
            width: 464px;
        }
        .appstore-section .rightpane .recom-body .cards-part .card1:nth-child(4n-1) {
            margin-left: 0 !important;
        }
        .appstore-section .rightpane .recom-body .cards-part .card1 {
            width: 220px !important;
        }
        
    }
    
    @media (min-width:320px) and (max-width:767px){
        .appstore-section .leftpane {
            width: 100%;
            margin-bottom: 35px;
        }
        .appstore-section .rightpane {
            width: 100%;
        }
        .appstore-section .rightpane .recom-body .cards-part .card1 {
            width: 220px !important;
        }
        .appstore-section .rightpane .rite-bar-set {
            width: 100%;
            margin: auto;
        }
        .appstore-section .rightpane .rite-bar-set .cards-part .card1 {
            width: 100% !important;
            max-width: 400px;
            margin-left: auto !important;
            margin-right: auto !important;
        }
        
    }
    
    @media (min-width:320px) and (max-width:340px){
        header#header a.brand img {
            width: 145px;
        }
        header a{
            font-size: 13px;
        }
    }
    
    @media (min-width:320px) and (max-width:480px){
        .appstore-section .rightpane .carosul .slider-wrapper.recom-body {
            width: 30vh;
            padding: 0;
            margin: auto;
        }
    }
    @media (min-width:481px) and (max-width:575px){
        .appstore-section .rightpane .carosul .slider-wrapper.recom-body {
            width: 49vh;
            padding: 0;
            margin: auto;
        }
    }
    @media (min-width:576px) and (max-width:767px){
        .appstore-section .rightpane .carosul .slider-wrapper.recom-body {
            width: 49vh;
            padding: 0;
            margin: auto;
        }
    }