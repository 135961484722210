.leftpane{
    width: 240px;
    background: #f1f3f4;
    padding-top: 85px;

    .first{

        .searchbar{
            margin: auto;
            height: 50px;
            width: 90%;
            display: flex;
            flex-direction: row;
            border: 1px solid #dadce0;
            background: #fff;
            line-height: 50px;
            position: relative;
            

            .searchicon{
                color: #9aa0a6;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 13px;
            }
            .search{
                outline: none;
                border: none;
                width: fit-content;
                padding: 10px 10px 10px 49px !important;
                box-sizing: border-box;
                width: 100%;
                
                &:hover{
                outline: #1a73e8;
                }
            }
        }

        .extension{
            background: #1a73e8;
            color: #fff;
            width: 200px;
            height: 45px;
            margin: 25px 0 10px 0 ;
            line-height: 45px;
            border-radius: 0 23px 23px 0;
            display: flex;
            flex-direction: row;
            p{
                width: fit-content;
                font-size: 18px;
            }
            .extension-icon{
                font-size:20px ;
                margin: 3px 20px ;
            }
        }

        .themes{
            color: #3c4043;
            width: 200px;
            height: 45px;
            margin: 0 0 10px 0 ;
            line-height: 45px;
            border-radius: 0 23px 23px 0;
            display: flex;
            flex-direction: row;
            p{
                width: fit-content;
                font-size: 18px;
            }
            .themes-icon{
                font-size:20px ;
                margin: 3px 20px;
            }
        }
        .seperator{
            background: #5f6368;
            width: 200px;
            height: 2px;
            margin: 0 auto;
        }
    }

    .second{
        padding: 20px 20px 0 20px;

        .categories{
            margin-bottom: 20px;
            h1{
                color: #3c4043;
                font-size: 15px;
                font-weight: bold;
                margin-bottom: 20px;
            }
            .second-dropdown{
                outline:none;
                border: 1px solid #dadce0;
                color: #3c4043;
                height: 40px;
                width: 100%;
                padding: 0 10px;
            }
            .option:hover{
                background: #5f6368;
            }
        }
        .features{
            h1{
                color: #3c4043;
                font-size: 15px;
                font-weight: bold;
                margin-bottom: 15px;
            }
            input{
                transform: scale(1.2);
                &:hover{
                    color: #1a73e8 !important;
                }
            }
            input,label{
                margin-bottom: 15px;
                color: #3c4043;
            }
            label{
                margin-left: 10px;
                font-size: 15px;
                &:hover{
                    color: #1a73e8;
                }
            }
        }
        .seperator{
            background: #5f6368;
            width: 200px;
            height: 2px;
            margin: 0 auto;
        }
    }
}

/*#bdc1c6*/